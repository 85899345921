import { selectedSchool } from "../../../../Layout"
import { schoolsContext } from "../../../../Layout"
import { filterContext } from "../../../../Layout"
import {useState,useContext,useEffect} from 'react'
import './Callout.css'
const Callout=({datasource,filter,description,size})=>{
    const [percentage,setpercentage]=useState(0)
    const [pop,setpop]=useState(0)
    const {schools,setschools}=useContext(schoolsContext)
    const {selected,setselected}=useContext(selectedSchool)
    const {filters,setfilters}=useContext(filterContext)
    function getPercentage(){
        let improved=schools.filter(school=>school.classification=='Improved')
        setpercentage(Math.floor(improved.length/schools.length*100))
    }
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function getPopulation(){
        let improved=schools.filter(school=>school.classification=='Improved')
        let population=improved.reduce((accum,curr)=>{
            return accum+curr.studentpopulation
        },0)
        setpop(numberWithCommas(population))
    }
    useEffect(()=>{
        getPercentage()
        getPopulation()
    },[schools])
    useEffect(()=>{
    },[selected])
    return(
        <>
         <div className="callout-container" style={{width:`${size}%`}}>
                <>
                 <div className="callout-figure">
                    <h1>{numberWithCommas(datasource)}</h1>
                </div>
                <div className="callout-narration">
                    {/* <h5><strong className="kpi-fact">primary schools</strong> are operational in {filters.ward || filters.scounty || 'Narok W. & Transmara W.'}</h5> */}
                    <h5>{description}</h5>
                </div>
                </>
                
            </div>
        </>
    )
}
export default Callout