import React, { useState, useEffect,useContext } from 'react';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Gannt from '../Gannt2'
const Tasks=()=>{
    return (
        <div className='task-div'>
            <Gannt/>
        </div>
    )
}
export default Tasks