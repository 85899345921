import { IoAnalyticsOutline } from "react-icons/io5";
const AmrefSchools=()=>{
    return(
        <div className='slide-legend'>
        <div className="select-map-item">
          <div className="sensor-reading-legend"><h1 style={{color:'#4a92c9'}}><IoAnalyticsOutline /></h1> </div>
          <p>Sensor Readings</p>
        </div>
        </div>
    )
}
export default AmrefSchools