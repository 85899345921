import {
    Source,
    Layer,
  } from "react-map-gl";
  import { filterContext } from "../../../Layout";
import { useContext } from "react";
const WardSource=()=>{
    // const featureFilter = ['==', ['get', 'NAME'],filters.ward.toUpperCase()]
    return (
        <Source
        id="my-vector-tileset"
        type="vector"
        url="mapbox://miscuit.4vefcpqt" // Replace with your tileset URL
      >
        <Layer
          id="vector-layer2"
          type="fill"
          source="my-vector-tileset"
          source-layer="N2S_wards-asr6x8" // Replace with the source layer of your tileset
          paint={{
             // Set the color of the borders
            'fill-color':'transparent',
            // Set the width of the borders
          }}
          // filter={featureFilter}
        />
        <Layer
          id="vector-layer"
          type="line"
          source="my-vector-tileset"
          source-layer="N2S_wards-asr6x8" // Replace with the source layer of your tileset
          paint={{
            'line-color': '#7c7d7e',
            // 'line-color': '#ACA7A7',
            'line-width': .7 // Set the width of the borders
          }}
          // filter={featureFilter}
        />
        
        </Source>
    )
}
export default WardSource