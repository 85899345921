import {
  Handle,
  Position,
  useNodesData,
  useHandleConnections,
} from "@xyflow/react";
import React, { useEffect, useState } from "react";
const Filter = () => {
  const [selected, setselected] = useState("");
  const [thedata,setthedata]=useState(null)
  // Get connection details
  const dataConnection = useHandleConnections({
    type: "target",
    id: "data",
  });

  // Fetch data from the connected node
  const data = useNodesData(dataConnection[0]?.source);

  // Log the fetched data to ensure it's being received
  useEffect(() => {
    if (data) {
      setthedata([...new Set(data.data.data.flatMap((obj) => Object.keys(obj)))])
    } else {
      return
    }
  }, [data]);
  return (
    <div className="data-operation">
      <div className="data-operation-header">
        <p>Data Filter</p>
      </div>
      <div className="data-operation-body">
        <select className="data-operation-dropdown">
            {!data &&<option value="">Attach data source to the left</option>}
            {data && data.data.data.length>0 && [...new Set(data.data.data.flatMap((obj) => Object.keys(obj)))].map((option,index)=>(
                <option value={option}>{option}</option>
            ))}
        </select>
      </div>
      <Handle type="target" id="data" position={Position.Left} />
      <Handle
        type="source"
        position={Position.Right}
        className="data-operation-target-handle"
      />
    </div>
  );
};
export default Filter;
