import React, { useState, useEffect,useMemo, useContext } from 'react';
import { Chart } from "react-charts";
import BounceLoader from "react-spinners/BounceLoader"
import './charts.css';
import RightPanel from '../../RightPanel/RightPanel';
import LeftPanel from '../../Left Panel/LeftPanel';
import { sensorContext } from '../../Slide';
const LineChart = () => {
  const [dataloading, setDataloading] = useState(true);  // Updated to manage loading state
  const [linedata, setLinedata] = useState([]);
  const {sensorfilters,setsensorfilters}=useContext(sensorContext)
  // Fetch sensor data
  const getData = async () => {
    try {
      const response = await fetch('/api/v1/getsensordata/', { method: 'POST', headers:{'Content-Type':'application/json'},body:JSON.stringify(sensorfilters) });
      const result = await response.json();
      setLinedata(result.data); // Set the data after fetching
    } catch (error) {
      console.error("Error fetching data: ", error); // Handle any fetch errors
    } finally {
      setDataloading(false); // Mark loading as complete
    }
  };
  
  // Prepare the data for the chart
  const preparedData = linedata.map(entry => ({
    x: new Date(entry.timestamp), // Convert timestamp to Date object
    y: entry.value                // Use the sensor value
  }));

  const data = [
    {
      label: 'Sensor Values',
      data: preparedData,
    }
  ];

  const primaryAxis = useMemo(
    () => ({
      getValue: datum => datum.x,
      primaryAxisType:'time',
      showGrid: false,
      show:true

    }),
    []
  )
  const secondaryAxes = useMemo(
    ()=> [
      {
        getValue: datum => datum.y,
        elementType: 'line',
        secondaryAxisType:'linear',
        show : true,
        showGrid: true,
      },
    ],
    []
  )

  // Fetch data on component mount
  useEffect(() => {
    getData();
  }, []); // Only run on mount
  useEffect(() => {
    getData();
  }, [sensorfilters]); // Only run on mount

  return (
    <>
    <RightPanel/>
    <div className='mapdiv'>
        <LeftPanel/>
      <div className='charts-container'>
        {dataloading ? (
          <p><BounceLoader color='#3fae89'
          loading={dataloading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"/></p> 
        ) : linedata.length > 0 ? (
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
            }}
          />
          
        ) : (
          <p>No data available to display</p>  
        )}
      </div>
    </div>
    </>
  );
};

export default LineChart;
