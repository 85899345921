import { Link } from "react-router-dom/cjs/react-router-dom"
const SectionCard=({data})=>{
    return(
        <div className="section-card">
            <div className="section-card-thumbnail" >
                <img className='thumbnail' src={data.image}></img>
            </div>
            <div className="section-card-content">
            <div className='section-card-project-name'>
            <h5>{data.project}</h5>
            </div>
            <div className='section-card-content-details'>
            <h3>{data.title}</h3>
                <p>{data.description}</p>
            </div>
            <div className={`tags ${data.tag_class} `}>
                    <h5>{data.tags}</h5>
            </div>
            </div>
        </div>
        
    )
}
export default SectionCard