import { Handle,Position ,useReactFlow} from "@xyflow/react"
import React, {useEffect, useState,useContext } from 'react';
import { slideContext } from "../../../Slide";
const Title=({id,data})=>{
    const {dashboard, setdashboard} = useContext(slideContext);
    const [title,settitle]=useState(dashboard.slidetitle)
    const [description,setdescription]=useState(dashboard.slidedescription)
    const { updateNodeData} = useReactFlow();
    useEffect(()=>{
        updateNodeData(id,{title:title})
    },[title])
    useEffect(()=>{
        updateNodeData(id,{description:description})
    },[description])
    return (
        <div className="component-node">
            <div className="component-node-header">
                <p>Slide Title</p>
            </div>
            <div className="component-node-body">
            <input className="component-node-input" value={title}  onChange={e=>settitle(e.target.value)} placeholder="Enter your slide title"></input>
            <input className="component-node-input" value={description} onChange={e=>setdescription(e.target.value)} placeholder="Enter your slide description"></input>
            </div>
            <Handle 
            type="source"
            id="data"
            position={Position.Right}
            className="title-target-handle"/>
        </div>
    )
}
export default Title;