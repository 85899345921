import './Highlights.css'
import CirclePoint from './CirclePoint'
import SquarePoint from './SquarePoint'
const Highlight=({showpoint, highlight,order})=>{
    
    return (
        <div className="slide-3-hightlight-container" style={{order:order}}>
            {showpoint && <div className='slide-3-point-container'>
                <SquarePoint/>
            </div>}
            <div className='slide-3-text-highlight-container' style={{width:showpoint?'85%':'100%'}}>
            <p>{highlight}</p>
            </div>
        </div>
    )
}
export default Highlight