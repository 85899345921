import React, { useState, useEffect,useContext } from 'react';
import { dashboardContext } from '../Dashboard';
import FormDialog from './DeploymentDialog';
const Numbers=()=>{
  const [numbers,setnumbers]=useState(null)
  const {dashboardfilters,setdashboardfilters}=useContext(dashboardContext)
  const getNumbers=()=>{
    console.log('fetching numbers');
      fetch('api/v1/getDashboardNumbers',{method:'POST',credentials:'include',headers:{'Content-Type':'application/json'},body:JSON.stringify(dashboardfilters)}).then(numbers=>numbers.json()).then(num=>{
        setnumbers(num.numbers)
      })
  }
  useEffect(()=>{
      getNumbers()
  },[])
  useEffect(()=>{
    getNumbers()
},[dashboardfilters])
    return ( 
        <div className='numbers-div'>
            
            
            <div className='number'>
            <h2>Projects' Progress - Gantt Chart</h2>
            <h5>
              Use this chart to track site progress in real time. Use the Well:Fair Companion tool to update the milestones which will be reflected here.
            </h5>
               {/* {numbers && <h1>{numbers.active}</h1>} */}
            </div>
            {/* <div className='number'>
            <h5>Completed Sites </h5>
            {numbers && <h1>{numbers.complete}</h1>}
            </div>
            <div className='number'>
            <h5>Incomplete Sites </h5>
            {numbers && <h1>{numbers.incomplete}</h1>}
            </div> */}
            
            {/* <div className='number add-deployment'> */}
            {/* <h5>+ New Deployment</h5> */}
           {/* <FormDialog/> */}
            {/* </div> */}
        </div>
    )
}
export default Numbers