import "./NodeSource.css";
import { MdFilterListAlt } from "react-icons/md";
import { useDnD } from "../../DragnDropDContext";
import { AiOutlineApi } from "react-icons/ai";
import { LuFilter } from "react-icons/lu";
import { BsDisplay } from "react-icons/bs";
import { IoText } from "react-icons/io5";
import { LuMapPin } from "react-icons/lu";

const Source = ({ icon, sourcename, target, description, typename }) => {
  const [_, setType] = useDnD();
  const onDragStart = (event, nodeType) => {
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  let icons = {
    apicall: AiOutlineApi,
    filter: LuFilter,
    viewport: BsDisplay,
    title: IoText,
    longlats:LuMapPin
  };
  const IconComponent = icons[icon];
  return (
    <div
      className="node-source"
      onDragStart={(event) => onDragStart(event, target)}
      draggable
    >
      <div className={`node-source-draggable ${typename}-bg`}></div>
      <div className="node-source-icon">
        <h3 className={typename}>{IconComponent && <IconComponent />}</h3>
      </div>
      <div className="node-source-details">
        <h5>{sourcename}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};
export default Source;
