import { useState } from 'react'
import './NodesSources.css'
import Source from './Source/NodeSource';
import { GrResources } from "react-icons/gr";
const NodesSources=()=>{
    const [open,setopen]=useState(false)
    return(
        <div className="nodes-sources-container-open">
            <Source sourcename='API Source' typename={'datasource'} icon={'apicall'} description='This is a data wrangling node used to import data from an API.' target='datasource'/>
            <Source sourcename='Data Filter' typename={'datawrangle'} icon={'filter'} description='This is a data wrangling node used in filtering data.' target='filter'/>
            <Source sourcename='Viewport' typename={'viewport'} icon={'viewport'} description='This is a component node that renders elements to the page.' target='viewport'/>
            <Source sourcename='Title'  typename={'uicomponent'} icon={'title'} description='This is a component node for setting a slide title and description.' target='title'/>
            <Source sourcename='Coordinates'  typename={'elementnode'} icon={'longlats'} description='This is an element node for parsing coordinates (longitudes,latitudes) from a dataset.' target='coordinates'/>
        </div>
    )
}
export default NodesSources