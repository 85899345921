import './pageheader.css'
import backgroundImage from '../../Images/water3.png'
const PageHeader=({image})=>{
    return (
        <div className="page-header-container background-tint" style={{backgroundImage: `url(${image})`}}>
            <div className='page-header-title'>
                <h2>Data driven solutions - 100% WASH Project.</h2>
            </div>
        </div>
    )
}
export default PageHeader