import {
    Handle,
    Position,
    useNodesData,
    useHandleConnections,
  } from "@xyflow/react";
import './componentnodes.css'
import { useContext, useEffect } from "react";
import { slideContext } from "../../../Slide";
const ViewPort=()=>{
    const {dashboard, setdashboard} = useContext(slideContext);
    const titleConnection = useHandleConnections({
        type: "target",
        id: "title",
      });
    const titledata = useNodesData(titleConnection[0]?.source);
    useEffect(()=>{
        if(titledata){
            setdashboard({...dashboard, slidetitle:titledata.data.title,slidedescription:titledata.data.description})
        }else{
            setdashboard({...dashboard, slidetitle:null,slidedescription:null})
        }
    },[titledata])
    return(
        <div className="viewport-node">
            <Handle 
            type="target"
            id="title"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="title" className="handle-label">
          Slide title
            </label>
            <Handle 
            type="target"
            id="callouts"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="callouts" className="handle-label">
          Callouts
            </label>
            <Handle 
            type="target"
            id="legend"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="legend" className="handle-label">
          Legend
            </label>
            <Handle 
            type="target"
            id="map"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="map" className="handle-label">
          Map
            </label>
            <Handle 
            type="target"
            id="filter"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="filter" className="handle-label">
          Filter Pane
            </label>
            <Handle 
            type="target"
            id="highlights"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="highlights" className="handle-label">
          Highlights Pane
            </label>
            <Handle 
            type="target"
            id="chart"
            className="viewport-handle"
            position={Position.Left}/>
            <label htmlFor="chart" className="handle-label">
          Chart
            </label>
        </div>
    )
}
export default ViewPort