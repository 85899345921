import './Informatic.css'
import Highlight from '../Hightlight/Hightlight'
import CalloutHighlight from '../Hightlight/CalloutHighlight'
import Callout from '../../../TopPanel/Callouts/Callout'
import { useContext,useEffect,useState} from 'react'
import { sensorContext } from '../../../Slide'
const Informatic=()=>{
    const {sensorfilters,setsensorfilters}=useContext(sensorContext)
    const sensors={'ec':'Water Salinity Levels','main':'Flow Rate','level':'Main Tank Level','ph':'Water pH','precipitation':'Area Precipitation (Rainfall)'}
    const highlights={'ec':['Water Salinity Levels (EC) measures the ability of water to conduct electricity, which correlates with the concentration of dissolved salts (salinity)',
'Higher conductivity indicates more salts in the water, which can affect its usability for drinking or irrigation.',
'Monitoring salinity is crucial in agriculture and drinking water systems, as high salinity can damage crops and make water unfit for consumption. It also provides an indication of potential water contamination from runoff or seawater intrusion.'],
'main':[
    'Flow rate measures the volume of water flowing through the system, typically when water is pumped from a borehole.',
    'This metric helps us assess how much water is being extracted and used over time.',
    'Useful for monitoring water usage efficiency, identifying potential leaks, or ensuring proper operation of pumping systems.'
],
'level':[
    'The Tank Level Sensor measures the current water level inside a storage tank, typically the main borehole tank.',
    'Water Level is measured in meters. We use these sensor to ensure timely refilling and preventing overflow.',
    'These metrics are essential for water management in systems relying on borehole water. Maintaining an appropriate water level prevents pump damage (from dry running) and ensures sufficient water supply.'
],
'ph':[
    'This sensor measures the acidity or alkalinity of water.',
    'pH < 7: Indicates acidic water.',
    'pH = 7: Neutral water.',
    'pH > 7: Indicates alkaline (basic) water.',
    'pH levels affect the safety and suitability of water for drinking, irrigation, and industrial use. Very acidic or alkaline water can damage pipes, affect soil health, or pose health risks.'
],
'precipitation':[
    'This sensor measures the amount of rainfall received in the area over time.',
    'These metrics help us monitor water availability and climate patterns. It’s important in flood forecasting. Understanding rainfall helps gauge whether the region is in a drought or flood condition.'
]}
      useEffect(()=>{
      },[])
    return(
        <div className="slide-3-informatic-div">
            <div className='slide-3-informatic-header'>
            <CalloutHighlight showpoint={false} element={Callout}/>
            {/* <Highlight showpoint={false}/> */}
            </div>
            <div className='slide-3-informatic-body'>
            {highlights[sensorfilters.sensor].map((highlight,index)=>(
            <Highlight key={index }showpoint={true} highlight={highlight} order={index+1}/>
            ))}
            </div>
        </div>
    )
}
export default Informatic