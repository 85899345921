import { useContext } from "react"
import { dashboardContext } from "../../Slide"
const Slidetitle=({slidetitle,slidedescription})=>{
    return(
        <>
            <div className='slide-title-container'>
        <div className='slide-title'>
                <div className="slide-title-header">
                    <div className="slide-title-text">
                    <h3>{slidetitle}</h3>
                    </div>
                    <div className="slide-title-class">
                    <h5>Real time monitoring</h5>
                    </div>
                </div>
                <div className="slide-title-description">
                <h5>{slidedescription}</h5>
                </div>
        </div>
        </div>
    </>
    )
}
export default Slidetitle