import './NodesContainer.css'
import { ReactFlow,
    MiniMap,
  Controls,
  Background,
  ReactFlowProvider,useNodesState, useEdgesState ,
    addEdge,useReactFlow} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import DataSource from './Nodes/DataNodes/DataSource';
import Filter from './Nodes/DataNodes/Filter';
import ViewPort from './Nodes/ComponentNodes/ViewPort';
import Title from './Nodes/ComponentNodes/Title';
import Points from './Nodes/ElementsNodes/Points';
import { useCallback,useEffect ,useRef,useState} from 'react';
import NodesSources from './SourcesContainer/Sources';
import { IoMdClose } from "react-icons/io";
import { DnDProvider,useDnD } from './DragnDropDContext';
import { generateRandomId } from '../../../utils';
import { IoCloudDoneOutline } from "react-icons/io5";
let flowKey='myflow'
const NodesContainer=({close})=>{
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );
  const { setViewport,screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const nodeTypes = {
    datasource: DataSource,
    filter:Filter,
    viewport:ViewPort,
    title:Title,
    coordinates:Points
    
  };
  const [rfInstance, setRfInstance] = useState(null);
  const onSave = useCallback(() => {
    console.log(nodes,edges);
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  }, [rfInstance]);
  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey));
      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };
    restoreFlow();
  }, [setNodes, setViewport]);
  let id = 0;
  const [type] = useDnD();
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      if (!type) {
        return;
      }
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: generateRandomId(),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [screenToFlowPosition, type]
    );
    useEffect(()=>{
        onRestore()
    },[])
    useEffect(()=>{
        onSave()
    },[nodes])
    useEffect(()=>{
        onSave()
    },[edges])
    return (
        <div className="nodes-container" ref={reactFlowWrapper}>
        <ReactFlow nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect} 
        nodeTypes={nodeTypes}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onInit={setRfInstance}>
            <Controls position='bottom-right'/>
        <MiniMap  nodeStrokeWidth={3} zoomable pannable position='bottom-center'/>
        <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
        <div className="nodes-close" onClick={e=>close()}> 
              <h5><IoMdClose className='node-close-icon'/></h5>
         </div>
         <div className="save-schema" onClick={e=>onSave()}> 
              <h5><IoCloudDoneOutline className='save-icon'/> All changes saved</h5>
         </div>
         <NodesSources/>
        </div>
    )
}
export default NodesContainer