import React, {
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";
import { ReactFlowProvider } from "@xyflow/react";
import { DnDProvider } from "./NodesContainer/DragnDropDContext";
import Graph from "./CentralPanel/Charts/LineChart";
import IconButton from "@mui/material/IconButton";
import { IoChevronBackOutline } from "react-icons/io5";
import { IoChevronForwardOutline } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import { pageContext } from "../../Layout";
import Slidetitle from "./TopPanel/SlideTitle/Slidetitle";
import SensorReadings from "./TopPanel/Legends/AmrefSchools";
import { AiOutlineNodeIndex } from "react-icons/ai";
import { slideCountContext } from "../../App";
import './Map.css'
import NodesContainer from "./NodesContainer/NodesContainer";
export const sensorContext=createContext()
export const slideContext = createContext();
const Slide = () => {
  const [shownodespanel, setshownodespanel] = useState(false);
  const twentyFourHoursAgo = new Date()
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 72);
  const [sensorfilters,setsensorfilters]=useState({
    sitename:'Oldonyo Rasha Primary School',
    sensor:'ec',
    startDate:new Date(twentyFourHoursAgo),
    endDate:new Date()
  })
  const close = () => {
    setshownodespanel(false);
  };
  const slideIndicators=[
  {
    id:'w-s-c',
    datasource:"/api/v1/getschoolwaterchoropleth",
    slidetitle:'How are the boreholes perfoming?',
    slidedescription:'Use this page to explore the various quantitative and qualitative attributes of our boreholes in the different sites. ',
    legend:SensorReadings

  }
  ]
  
  const [dashboard, setdashboard] = useState(slideIndicators[0]);
  useEffect(()=>{},[dashboard])
  return (
      
  <div className="sensor-map">
  {/* <div className="nodes-button" onClick={(e) => setshownodespanel(true)}>
          <h3>
            <Tooltip title="Open Node View">
              <IconButton>
                <AiOutlineNodeIndex />
              </IconButton>
            </Tooltip>
          </h3>
        </div>
        <div className="previous-button">
          <h3>
            <Tooltip title="Open Node View">
              <IconButton>
                <IoChevronBackOutline />
              </IconButton>
            </Tooltip>
          </h3>
        </div>
        <div className="next-button" >
          <h3>
            <Tooltip title="Open Node View">
              <IconButton>
                <IoChevronForwardOutline />
              </IconButton>
            </Tooltip>
          </h3>
        </div> */}
        <div className="slide-top-panel">
          {shownodespanel && (
            <ReactFlowProvider>
              <DnDProvider>
                <NodesContainer close={close}/>
              </DnDProvider>
            </ReactFlowProvider>
          )}
           <Slidetitle
            slidetitle={dashboard.slidetitle}
            slidedescription={dashboard.slidedescription}
          />
          {dashboard.legend && <dashboard.legend />}
          {/* <CalloutContainer /> */}
        </div>
        <sensorContext.Provider value={{sensorfilters,setsensorfilters}}>
        <div className="slide-bottom-panel">
          <Graph/>
        </div>
        </sensorContext.Provider>
      </div>
  );
};
export default Slide;
