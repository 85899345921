import React, { useState, useEffect,useReducer } from 'react';
import { useParams } from 'react-router';
import { userContext } from '../../Contexts/useContext';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '../../components/Drawer/Drawer'
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import BounceLoader from "react-spinners/BounceLoader"
import TextField from '@mui/material/TextField';
const GanttChart=({view})=>{
  const user=React.useContext(userContext)
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //     return <Slide direction="up" ref={ref} {...props} />;
    //   });
    const params= useParams()
    const [siteid,setsiteid]=useState(params.id)
    const [site,setsite]=useState(params.sitename)
    const [tasks,settasks]=useState(null)
    const [loading,setloading]=useState(false)
    const [tobeupdated,settobeupdated]=useState(null)
    const [openDialog,setopenDialog]=useState(false)
    const [reason,setreason]=useState(null)
    const [task,settask]=useState(null)
    const [showDrawer,setshowDrawer]=useState(false)
    const [clickedSection,setclickedSection]=useState(null)
    const fetchSections=()=>{
      setloading(true)
        fetch(`/api/v1/getSiteSectionsActual/${siteid}`).then(sections=>sections.json()).then(sects=>{
            let thetasks=[]
           let mysects= sects.sections.sort((a,b)=>{
                return  a.componentOrder-b.componentOrder || a.sectionOrder-b.sectionOrder 
            })
            mysects.forEach(section=>{
                let colors=['#a33418','#10de84','#ffbb54']
                let task={
                    start:new Date(section.startDate),
                    end:new Date(section.dueDate),
                    name:section.sectionname,
                    status:section.status,
                    id:section.sectionname,
                    _id:section._id,
                    dependencies:section.dependancies,
                    type:'task',
                    progress:section.progress,
                    styles: { backgroundColor: `${section.status=='Overdue'? '#a33418' : '#cccccc'}`, progressSelectedColor: '#ff9e0d',progressColor:'#359c68' }
                }
                thetasks.push(task)
            })
            settasks(thetasks)
            setloading(false)
        })
    }
    const fetchSectionsEngineer=()=>{
      fetch(`/api/v1/getEngineerSectionsActual/${user.username}`).then(sections=>sections.json()).then(sects=>{
          let thetasks=[]
         let mysects= sects.sections.sort((a,b)=>{
              return  a.componentOrder-b.componentOrder || a.sectionOrder-b.sectionOrder 
          })
          mysects.forEach(section=>{
              let colors=['#a33418','#10de84','#ffbb54']
              let task={
                  start:new Date(section.startDate),
                  end:new Date(section.dueDate),
                  name:`${section.sectionname}(${section.sitename})`,
                  id:section.sectionname,
                  taskid:section._id,
                  dependencies:section.dependancies,
                  type:'task',
                  progress:section.progress,
                  styles: { backgroundColor: `${section.status=='Overdue'? '#a33418' : '#cccccc'}`, progressSelectedColor: '#ff9e0d',progressColor:'#359c68' }
              }
              thetasks.push(task)
          })
          settasks(thetasks)

      })
  }
    const updateDate=(task)=>{
        const body={
            start:task.start,
            end:task.end,
            reason:reason
        }
        fetch(`/api/v1/updatedate/${task._id}`,{method:'POST',credentials:'include',body:JSON.stringify(body),headers:{'Content-Type':'application/json'}})
            .then(response=>response.json())
            .then(res=>{
                if(res.done){
        fetchSections()

                }
            })
    }
    const onDateChange=(task)=>{
        settobeupdated(task.name)
        setopenDialog(true)
        settask(task)

    }   
    function capitalizeFirstLetter(sentence) {
      return sentence
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    const handleClose = () => {
      window.location.reload();
      };
    const handleUpdate=()=>{
      setopenDialog(false);
      updateDate(task)
    }
    const onCancel=()=>{
      window.location.reload();
    }
    const showD=(task)=>{
      setclickedSection(task)
      setshowDrawer(!showDrawer)
    }
    useEffect(()=>{
        fetchSections()
        // fetchSectionsEngineer()
    },[])
    useEffect(()=>{
      fetchSections()
      // fetchSectionsEngineer()
  },[])
    return ( 
        <>
       <div className="gantt-chart-container">

         <div className="gantt-chart-container-header">
                  <h2>{capitalizeFirstLetter(site)}</h2>
                  <h5>Track the milestones for this site using the gantt chart below. Red milestones have surpassed their allocated timelines. Hover on a milestone to view its details and its progress towards completion</h5>
        </div>
        {loading && <p><BounceLoader color='#3fae89'
          loading={loading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"/></p>}
        {tasks &&
        <div className="gantt-chart-container-gantt">
          <Gantt tasks={tasks}
                    ganttHeight={500}
                    viewMode={'Month'}
                    fontFamily='Cera Pro2'
                    fontSize='11px'
                    onDateChange={onDateChange}
                    rowHeight={35}
                    onDoubleClick={showD}/>
        </div> 
                    }
        {showDrawer &&
        <Drawer close={showD}  page={'tasks'} innerpage={'taskview'} section={clickedSection}/>
        }
        {openDialog && 
        <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{fontFamily:'Cera Pro2'}}>{"Milestone Update"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{fontFamily:'Cera Pro2'}}>
            You are changing the timelines for <strong>{tobeupdated}</strong>, to proceed, provide a reason for date revision
            <TextField
          sx={{fontFamily:'Cera Pro'}}
            autoFocus
            margin="dense"
            id="name"
            label="Alteration reason"
            type="text"
            fullWidth
            // variant="standard"
            onChange={e=>setreason(e.target.value)}
          />
          
          </DialogContentText>

        </DialogContent>
        <DialogActions>
        <Button onClick={onCancel} sx={{fontFamily:'Cera Pro',color:'red'}}
          >Cancel</Button>
          <Button onClick={handleUpdate} sx={{fontFamily:'Cera Pro'}}
          >Update Milestone</Button>
        </DialogActions>
      </Dialog>}
      </div>
        </>
    )
}
export default GanttChart