import {
  Handle,
  Position,
  useNodesData,
  useHandleConnections,
} from "@xyflow/react";
import React, { useEffect, useState } from "react";
import './elements.css'
const Points = () => {
  const dataConnection = useHandleConnections({
    type: "target",
    id: "data",
  });

  // Fetch data from the connected node
  const data = useNodesData(dataConnection[0]?.source);
  return (
    <div className="element-node">
      <div className="element-node-header">
        <p>Coordinates</p>
      </div>
      <div className="element-node-body">
        <label htmlFor="lat" className="dropdown-label">Select latitude column</label>
        <select className="element-node-dropdown" id="lat">
          {!data && <option value="">Attach data source to the left</option>}
          {data &&
            data.data.data.length > 0 &&
            [...new Set(data.data.data.flatMap((obj) => Object.keys(obj)))].map(
              (option, index) => <option value={option}>{option}</option>
            )}
        </select>
        <label htmlFor="long" className="dropdown-label">Select longitude column</label>
        <select className="element-node-dropdown" id="long">
          {!data && <option value="">Attach data source to the left</option>}
          {data &&
            data.data.data.length > 0 &&
            [...new Set(data.data.data.flatMap((obj) => Object.keys(obj)))].map(
              (option, index) => <option value={option}>{option}</option>
            )}
        </select>
      </div>
      <Handle type="target" id="data" position={Position.Left} />
      <Handle
        type="source"
        position={Position.Right}
        className="element-node-target-handle"
      />
    </div>
  );
};
export default Points;
