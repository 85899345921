import Dropdown from './LeftPanelComponents/Dropdowns/Dropdown'
import Header from './LeftPanelComponents/Header/Header';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { sensorContext } from '../Slide';
import dayjs, { Dayjs } from 'dayjs';
import {useState,useContext} from 'react'
const LeftPanel=()=>{
    const {sensorfilters,setsensorfilters}=useContext(sensorContext)
    const [site,setsite]=useState(sensorfilters.sitename)
    const [sensor,setsensor]=useState(sensorfilters.sensor)
    const [startdate,setstartdate]=useState(sensorfilters.startDate)
    const [enddate,setenddate]=useState(sensorfilters.endDate)
    const changeState=(value,thestate)=>{
        switch (thestate) {
            case 'site':
                setsensorfilters({...sensorfilters,sitename:value})
                break;
            case 'sensor':
                setsensorfilters({...sensorfilters,sensor:value})
                break;
            default:
                break;
        }
    }
    let sites = [
        {label:"Compare Sites",
        value:"Compare Sites"},
        {label:"Oldonyo Rasha Primary School",
        value:"Oldonyo Rasha Primary School"},
        {label:"Endoinyo Narasha Primary School",
        value:"Endoinyo Narasha Primary School"},
        {label:"Enemasi Primary School",
        value:"Enemasi Primary School"},
        {label:"Ilmochin Primary School",
        value:"Ilmochin Primary School"},
        {label:"Kijirjir Primary School",
        value:"Kijirjir Primary School"},
        {label:"Nkairuwani Primary School",
        value:"Nkairuwani Primary School"},
      ];
    let sensors=[{
        label:'Flow Rate',
        value:'main'
    },{
        label:'Main Tank Level',
        value:'level'
    },
    {
        label:'Water Salinity Levels',
        value:'ec'
    },
    {
        label:'Water pH',
        value:'ph'
    },
    {
        label:'Area Precipitation (Rainfall)',
        value:'precipitation'
    }]
    
    return(
        <div className="left-panel">
            <Header title='Filter Panel' description='Manipulate map and callout data using the filters below'/>
            <Dropdown filteroptions={sites} label={'Select Site'} change={changeState} thestate='site'/>
            <Dropdown filteroptions={sensors} label={'Select Sensor'} change={changeState} thestate='sensor'/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker fullWidth slotProps={{ textField: { size: 'small' } }} value ={dayjs(sensorfilters.startDate)} sx={{ m: 1,minWidth:'80%;'}} label="Select Start Date" onChange={newdate=>setsensorfilters({...sensorfilters,startDate:newdate})} />
            </LocalizationProvider>
            <LocalizationProvider>
            <DatePicker fullWidth slotProps={{ textField: { size: 'small' } }} value ={dayjs(sensorfilters.endDate)}  sx={{ m: 1, minWidth:'80%;' }} label="Select End Date" onChange={newdate=>setsensorfilters({...sensorfilters,endDate:newdate})}/>
            </LocalizationProvider>
            {/* <Dropdown filteroptions={interventions} label={'Start Date'}/>
            <Dropdown filteroptions={interventions} label={'End Date'}/> */}
        </div>
    )
}
export default LeftPanel;