import './Highlights.css'
import CirclePoint from './CirclePoint'
import SquarePoint from './SquarePoint'
import Callout from '../../../TopPanel/Callouts/Callout'
import { sensorContext } from '../../../Slide'
import { useContext,useEffect,useState} from 'react'
const Highlight=({showpoint})=>{
    const [currentreading,setcurrentreading]=useState('0')
    const {sensorfilters,setsensorfilters}=useContext(sensorContext)
    const sensors={'ec':'Water Salinity Levels','main':'Flow Rate','level':'Main Tank Level','ph':'Water pH','precipitation':'Area Precipitation (Rainfall)'}
    const getData = async () => {
        try {
          const response = await fetch('/api/v1/getsensordata/', { method: 'POST', headers:{'Content-Type':'application/json'},body:JSON.stringify(sensorfilters) });
          const result = await response.json();
          let datalength=result.data.length
          setcurrentreading(Math.ceil(result.data[0].value)); // Set the data after fetching
        } catch (error) {
          console.error("Error fetching data: ", error); // Handle any fetch errors
        } 
      };
      useEffect(()=>{
        getData()
      },[])
      useEffect(()=>{
        getData()
      },[sensorfilters])
    return (
        <div className="hightlight-container">
            {showpoint && <div className='point-container'>
                <SquarePoint/>
            </div>}
            <div className='text-highlight-container' style={{width:showpoint?'85%':'100%'}}>
            <Callout datasource={currentreading} description={`Current reading for ${sensors[sensorfilters.sensor]} at ${sensorfilters.sitename}`} size={100}/>
            </div>
        </div>
    )
}
export default Highlight