export const homeLinks=[
    {
        project:'100% WASH Project',
        title:"Here's what we are doing in the 100% WASH Project - Water Access",
        description:'A dashboard showing our interventions and accomplishments in provision of safe drinking water to communities of Narok West and Transmara West',
        link:'/water-access',
        image:'/water3.jpg',
        tags:'Data Story',
        tag_class:'tags-story'
    },
    {
        project:'100% WASH Project',
        title:"Here's what we are doing in the 100% WASH Project - Sanitation Coverage",
        description:'A dashboard showing our interventions and accomplishments in CLTS works within Narok West and Transmara West',
        link:'/sanitation-coverage',
        image:'/thumbnail.png',
        tags:'Data Tool',
        tag_class:'tags-tool'
    },
    {
        project:'100% WASH Project',
        title:"Ongoing Sites Progress Monitoring",
        description:'We use a gantt chart to see how well we are moving with the projects. The gantt is updated automatically through our project companion tool where we update our milestones',
        link:'/gantt',
        image:'/gantt.jpg',
        tags:'Data Visualization',
        tag_class:'tags-charts'
    },
    {
        project:'100% WASH Project',
        title:"Boreholes Remote Sensing",
        description:'A real time chart showing how the different boreholes are doing in terms of water quality and quantity.',
        link:'/water-access/sensor-readings',
        image:'/sensor.jpg',
        tags:'Data Visualization',
        tag_class:'tags-charts'
    }
    // ,
    // {
    //     project:'100% WASH Project',
    //     title:"Narok West, Transmara West - Ground Water Potential",
    //     description:'Layer different map outputs from our ground water mapping exercise to guide your decision on water interventions in Narok West and Transmara West.',
    //     link:'/gantt',
    //     image:'/gantt.jpg',
    //     tags:'Map',
    //     tag_class:'tags-map'
    // },
    // {
    //     project:'100% WASH Project',
    //     title:"Digitized Form A and Form B for CLTS household followups",
    //     description:'This tool builds digitized forms for CLTS works allowing your ward to effectively track progress made by households',
    //     link:'/gantt',
    //     image:'/clts.jpg',
    //     tags:'Data Tool',
    //     tag_class:'tags-tool'
    // }
]
