const SanitationChoropleth=()=>{
    return(
        <>
        <div className="select-map-item">
          <div className="pallet-2-20"></div>
          <p>{"0-20% toilet coverage"}</p>
        </div>
        <div className="select-map-item">
          <div className="pallet-2-40"></div>
          <p>{"20-40% toilet coverage"}</p>
        </div>
        <div className="select-map-item">
          <div className="pallet-2-60"></div>
          <p>{"40-60% toilet coverage"}</p>
        </div>
        <div className="select-map-item">
          <div className="pallet-2-80"></div>
          <p>{"60-80% toilet coverage"}</p>
        </div>
        <div className="select-map-item">
          <div className="pallet-2-100"></div>
          <p>{"80-100% toilet coverage"}</p>
        </div>
        <div className="select-map-item">
          <div className="pallet-no-data"></div>
          <p>{"No villages tracked"}</p>
        </div>
        </>
    )
}
export default SanitationChoropleth