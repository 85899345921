import ReactMapGl, {
    Marker,
    Popup,
    Source,
    Layer,
    NavigationControl,
    GeolocateControl,
  } from "react-map-gl";
  import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useMemo
  } from "react";
import { villagesContext } from "../../../../Layout";
import { schoolsContext } from "../../../../Layout";
import { selectedSchool } from "../../../../Layout";
import { filterContext } from "../../../../Layout";
import { AxisOptions, Chart } from "react-charts";
import { selectedOptionContext } from "../../../../Layout";
const SchoolPoints=({id})=>{
    const mapRef = useRef(null);
    const {villages,setvillages}=useContext(villagesContext)
    const { selectedOption, setSelectedOption } = useContext(selectedOptionContext);
    const { filters, setfilters } = useContext(filterContext);
    const { schools, setschools } = useContext(schoolsContext);
    const [getschools, setgetschools] = useState([]);
    const [getcoord, setgetcoord] = useState(null);
    const [coord, setcoord] = useState(null);
    const { selected, setselected } = useContext(selectedSchool);
  const [mapcenter, setmapcenter] = useState([35.624266, -1.330503]);
  const [showpopup, setshowpopup] = useState({ index: null, show: false });

    function filterSchools(body) {
        if (getcoord) {
          let filteredcoord = getcoord.filter((sch) => {
            if (body.ward) {
              return sch.properties.ward === body.ward;
            }
            if (body.scounty) {
              return sch.properties.scounty === body.scounty;
            }
            if (body.intervention) {
              return sch.properties.intervention === body.intervention;
            }
            if (Object.keys(body).length < 1) {
              return sch;
            }
          });
          setcoord(filteredcoord);
        }
        if (schools) {
          let filteredschools = getschools.filter((sch) => {
            if (body.ward) {
              return sch.ward === body.ward;
            }
            if (body.scounty) {
              return sch.scounty === body.scounty;
            }
            if (body.intervention) {
              return sch.intervention === body.intervention;
            }
            if (Object.keys(body).length < 1) {
              return sch;
            }
          });
          setschools(filteredschools);
        }
      }
    function fetchSchools() {
      if(id=='w-s-p'){
        fetch("/api/v1/getschoolpoints", { method: "GET", credentials: "include" })
          .then((points) => points.json())
          .then((schools) => {
            setcoord(schools.map.features);
            setgetcoord(schools.map.features);
            setgetschools(schools.schools);
            setschools(schools.schools);
          });
      }
      if(id=='w-a-p'){
        fetch("/api/v1/interventionsites", { method: "GET", credentials: "include" })
          .then((points) => points.json())
          .then((schools) => {
            setcoord(schools.map.features);
            setgetcoord(schools.map.features);
            setgetschools(schools.schools);
            setschools(schools.schools);
          });
      }
      if(id=='w-a-p-k'){
        fetch("/api/v1/getwaterpoints", { method: "GET", credentials: "include" })
          .then((points) => points.json())
          .then((points) => {
            setcoord(points.map.features);
            setgetcoord(points.map.features);
          });
      }
      }
      function flyToSchool(schoolname) {
        if (schoolname) {
          let sch = schools.filter((school) => school.name == schoolname);
          setmapcenter([sch[0].long, sch[0].lat]);
          mapRef.current?.flyTo({ center: [sch[0].long, sch[0].lat], zoom: 17 });
        } else {
          setmapcenter([35.3244957, -1.1367309]);
          mapRef.current?.flyTo({ center: [35.3244957, -1.1367309], zoom: 8 });
        }
      }
      // const primaryAxis = useMemo(
      //   () => ({
      //     getValue: datum => datum.label,
      //   }),
      //   []
      // )
      // const secondaryAxes = useMemo(
      //   ()=> [
      //     {
      //       getValue: datum => datum.toilets,
      //       elementType: element,
      //       secondaryAxisType:'time',
      //       secondaryAxisShow : false
      //     },
      //   ],
      //   []
      // )
      useEffect(() => {
        fetchSchools();
    }, []);
      useEffect(() => {
        if (selected) {
          flyToSchool(selected.label);
        }
      }, [selected]);
      useEffect(() => {
        filterSchools(filters);
        // flyToSchool('cleared')
      }, [filters]);
      useEffect(() => {}, [coord]);
    return(
        <>
        {coord &&
        coord.map((coord, index) => (
          <div key={coord.id}>
            <Marker
              latitude={coord.geometry.coordinates[1]}
              longitude={coord.geometry.coordinates[0]}>
              <div
                className="marker"
                onMouseEnter={() =>
                  setshowpopup({ index: coord.id, show: true })
                }
                onMouseLeave={() => setshowpopup({ index: null, show: false })}
              >
                {!coord.properties.isIntervention && (
                  <div
                    className={`blob${coord.properties.classification}`}
                    onClick={(e) => {
                      flyToSchool(coord.properties.name);
                      setSelectedOption({
                        label: coord.properties.name,
                        value: coord.properties.value,
                        population: coord.properties.population,
                        girls: coord.properties.girls,
                        boys: coord.properties.boys,
                        borehole: coord.properties.water,
                      });
                      // setmapcenter([
                      //   coord.geometry.coordinates[0],
                      //   coord.geometry.coordinates[1],
                      // ]);
                      // setzoom(17);
                    }}
                  ></div>
                )}
                {id=='w-a-p-k'  && (
                  <div
                    className={`blobintervention`}
                  ></div>
                )}
                {id=='w-a-p' && coord.properties.isIntervention && (
                  <div
                    className="blobintervention"
                    onClick={(e) => {
                      flyToSchool(coord.properties.name);
                      setSelectedOption({
                        label: coord.properties.name,
                        value: coord.properties.value,
                        population: coord.properties.population,
                        girls: coord.properties.girls,
                        boys: coord.properties.boys,
                        borehole: coord.properties.water,
                      });
                    }}
                  ></div>
                )}
              </div>
            </Marker>
            {showpopup.index == coord.id && (
              <Popup
                key={index}
                longitude={coord.geometry.coordinates[0]}
                latitude={coord.geometry.coordinates[1]}
              >
                <div>
                  <p className="schoolnamepopup">{coord.properties.name}</p>
                  <p className="schoolnameward">Main water Source: {coord.properties.mainWaterSource}</p>
                  <hr style={{color:'grey',margin:'10px 0 20px 0'}}></hr>
                  <div style={{display:'flex',flexDirection:'row',height:'100px'}}>
                    <div style={{display:'flex',order:'1',width:'40%',borderRight:'0.5px solid grey', flexDirection:'column'}}>
                  <p className="schoolpopulation">{coord.properties.boys+coord.properties.girls}</p>
                  <p className="schoolnameward" style={{marginTop:'10px'}}>Total Students</p>

                    </div>
                  </div>
                  
                  
                </div>
              </Popup>
            )}
            )
          </div>
        ))}</>
    )
}
export default SchoolPoints