import * as React from 'react';
import Box from '@mui/material/Box';
import MapSettings from './MapSettings';
// import './Drawer.css'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import InsightsDrawer from './InsightsDrawer';
import { drawerContext } from '../../Layout';
export default function AnchorTemporaryDrawer() {
  
  let heights={
    "insights":'95%',
    "mapsettings":'95%'
  }
  const [state, setState] = React.useState({
    right: false,
  });
  function changeDrawerState(){
    setTimeout(()=>{setshowDrawer(false)},200)
  }
  const {showDrawer,setshowDrawer}=React.useContext(drawerContext)
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    changeDrawerState()
  };  
  React.useEffect(()=>{
    setState({right:true})
    toggleDrawer('right',true)
  },[])
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '40vw' ,height:heights[showDrawer.purpose]}}
      role="presentation"
    >
        {showDrawer.purpose=='insights' && <InsightsDrawer/>}
        {showDrawer.purpose=='mapsettings' && <MapSettings/>}
    </Box>
  );
  return (
    <div className='floating-menu'
    >
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            PaperProps={{ elevation: 0, style: { backgroundColor: "white",borderRadius:'10px' ,marginRight:'30px',height:'95vh',marginTop:'2.5vh'} }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}