import { Handle,Position ,useReactFlow} from "@xyflow/react"
import React, {useEffect, useState } from 'react';
import './datanodes.css'
import PuffLoader from "react-spinners/PuffLoader";
const DataSource=({id,data})=>{
    const [text,settext]=useState(null)
    const [loading,setloading]=useState(false)
    const [newdata,setnewdata]=useState(data)
    const { updateNodeData} = useReactFlow();
    const getData=()=>{
        setloading(true)
        fetch(text,{method:'GET'})
        .then(data=>data.json())
        .then(data=>{
            setloading(false)
            setnewdata(data)
            updateNodeData(id,{data:data});
        })
    }
    return (
        <div className="data-source">
            <div className="data-source-header">
                <p>API Request</p>
            </div>
            <div className="data-source-body">
                <input className="data-source-url-input"  onChange={e=>settext(e.target.value)} placeholder="https://your-data-source"></input>
                {loading && <button className="data-source-button" onClick={e=>getData()}>
                <PuffLoader size={10} color="#fff"/>
                    <p> Fetching Data</p>
                </button>}
                {text && !loading && <button className="data-source-button" onClick={e=>getData()}>
                    <p>Fetch Data</p>
                </button>}
            </div>
            <Handle 
            type="source"
            id="data"
            position={Position.Right}
            className="data-source-target-handle"/>
        </div>
    )
}
export default DataSource;