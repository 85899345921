import { Source, Layer } from "react-map-gl";
import { useEffect, useState, useCallback } from "react";

const Choropleth = ({datasource,pallete}) => {
  const [data, setData] = useState({});
  const [style, setMapStyle] = useState(null);

  const setMap = (data) => {
    setMapStyle({
      version: 8,
      sources: {
        "my-vector-source": {
          type: "vector",
          url: "mapbox://miscuit.4vefcpqt", // Replace with your vector tileset URL
        },
      },
      layers: [
        {
          id: "vector-layer1",
          type: "fill",
          source: "my-vector-source",
          "source-layer": "N2S_wards-asr6x8", // Replace with your source layer
          paint: {
            "fill-color": [
              "match",
              ["get", "ward"],
              ...Object.entries(data).flatMap(([ward, percentage]) => [
                ward,
                getColorPallet(percentage),
              ]),
              "#ccc", // Default color if no match
            ],
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              1, // Opacity on hover
              0.8, // Default opacity
            ],
          },
        },
      ],
    });
  };

  const fetchData = async () => {
    try {
      const response =await fetch(datasource)
      const result = await response.json();
      setData(result.data || result.villages);
      setMap(result.data || result.villages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getColorPallet = (percentage) => {
    if (pallete === "w-s-c") {
      if (percentage <= 20) return "#341363"; // Lightest color
      if (percentage <= 40) return "#8C297B";
      if (percentage <= 60) return "#D74D69";
      if (percentage <= 80) return "#FB8E68";
      return "#FED69A"; // Darkest color
    }
    if (pallete === "s-w-c") {
      if (percentage <= 20) return "#E4EDB4"; // Lightest color
      if (percentage <= 40) return "#D3E4A6";
      if (percentage <= 60) return "#9EC87A";
      if (percentage <= 80) return "#409B32";
      return "#256D1E"; // Darkest color
    }
  };

  useEffect(() => {
    fetchData();
  }, [datasource]);
  return (
    <>
      {data && (
        <Source
          id="my-vector-source"
          type="vector"
          promoteId="uid"
          url="mapbox://miscuit.4vefcpqt" // Replace with your tileset URL
        >
          <>
            {style && (
              <Layer
                className="data-layer"
                id="vector-layer1"
                type="fill"
                source="my-vector-source"
                source-layer="N2S_wards-asr6x8" // Replace with your source layer of your tileset
                paint={style.layers[0].paint} // Use the paint property from the style state
              />
            )}
            {style && (
              <Layer
                id="another-id"
                type="line"
                source="my-vector-source"
                source-layer="N2S_wards-asr6x8" // Replace with the source layer of your tileset
                paint={{
                  "line-color": "#ffffff",
                  "line-width": 0.3, // Set the width of the borders
                }}
              />
            )}
          </>
        </Source>
      )}
    </>
  );
};

export default Choropleth;
