import React, { useState, useEffect, createContext} from 'react';
import Numbers from './Numbers/Numbers';
import Tasks from './Tasks/Tasks';
import Sitefilter from './Sites/Sitesfilter';
import Sites from './Sites/Sites';
export const dashboardContext=createContext();
const Dashboard=()=>{
const [dashboardfilters,setdashboardfilters]=useState({deployment:null,subcounty:null,intervention:null})
return( 
    <dashboardContext.Provider value={{dashboardfilters,setdashboardfilters}}>
    <div className='dashboard-div'>
         <div className='dashboard-div1'>
             <Numbers/>
            {/* <Sitefilter/> */}
         </div>
    <div className='dashboard-div2'>
             <Tasks/>
            {/* <Sites/> */}
    </div>
    </div>
    </dashboardContext.Provider>
   
)
}
export default Dashboard