import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import './Dropdown.css'
import { useState, useEffect,useContext } from "react";
import { sensorContext } from "../../../Slide";
const DropdownFilter = ({ filteroptions, label, change, thestate }) => {
  const {sensorfilters,setsensorfilters}=useContext(sensorContext)
  const [selected, setselected] = useState(thestate=='site'?sensorfilters.sitename:sensorfilters.sensor);
  return (
    <div className="dropdown-container">
      <FormControl fullWidth size="small" sx={{ m: 1, minWidth: "100%" }}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
        >
          {label}
        </InputLabel>
        <Select
          onChange={(e) => {
            setselected(e.target.value);
            change(e.target.value,thestate)
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          sx={{
            fontFamily: "Cera Pro1",
            fontSize: "13px",
            textAlign: "left",
          }}
          value={selected}
        >
          {filteroptions.map((option, index) => (
            <MenuItem
              sx={{ fontFamily: "Cera Pro1", fontSize: "13px" }}
              className="menuitem"
              value={option.value}
              key={index}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default DropdownFilter;
