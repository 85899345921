import SectionCard from "./sectioncard"
import './section.css'
import { homeLinks } from "../../Data/HomepageLinks"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
const Section=({sectionheader,sectioncontent})=>{
    return (
        <div className="section-container">
            <div className="section-container-header">
                {/* <h2>Solutions Portfolio</h2> */}
                <div className="tags-filter tags-tool">
                    <h5>Data Tools</h5>
                </div>
                <div className="tags-filter tags-story">
                    <h5>Data Stories</h5>
                </div>
                <div className="tags-filter tags-charts">
                    <h5>Data Visualizations</h5>
                </div>
                <div className="tags-filter tags-map">
                    <h5>Maps</h5>
                </div>
             </div>
            <div className="section-container-body">
                {homeLinks.map((data,index)=>(
                    <Link to={data.link}>
                    <SectionCard key={index} data={data}/>
                    </Link>
                ))}
                
            </div>
        </div>
    )
}
export default Section